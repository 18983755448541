{
  "api": {
    "origin": "https://us-east4-main-int-scansies-com.cloudfunctions.net",
    "prefix": "https://us-east4-main-int-scansies-com.cloudfunctions.net"
  },
  "cdnOrigin": "https://paper.ponder-cdn.com",
  "googleClientId": "605333750518-nds9pnjii9au72m7geu6ivtfp239bopv.apps.googleusercontent.com",
  "meta": {
    "branch": "live/main",
    "data": "live",
    "dbId": "usi-I",
    "host": "main-int.scansies.com",
    "ns": "int",
    "type": "main-int",
    "version": "7.0",
    "where": "int"
  },
  "rollbarClientKey": "900af1794ea245b5b8200fd25561d14f"
}
