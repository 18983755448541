import { APIs } from '@paper/api-specs'
import { LoginData, SchoolYear, WebappUser } from '@paper/schema'
import { createFetch, Fetcher, fetcher, getInternalUrl } from '@paper/utils'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { createContext, useContext } from 'react'
import { FullPageLoading } from '~src/components/status'
import { Public } from '~src/pages/public'
import { Telemetry } from '~src/telemetry'
import config from '~src/utils/config'
import rollbar from '~src/utils/rollbar'

const TOKEN_KEY = 'token'
const TOKEN_STORED_AT_INIT = localStorage.getItem(TOKEN_KEY)

// these are currently static
const { queryKey: USER_QUERY_KEY, url: urlPath } =
  APIs['auth.checktoken'].factory(undefined)

type IUserContext = {
  fetchAs: Fetcher
  isInternal: boolean
  isSuperDuperUser: boolean
  signOut(manual: boolean): void
  user: WebappUser
  year: LoginData['year']
  years: SchoolYear[]
}
const UserContext = createContext<IUserContext>(undefined)
export const useUser = () => useContext(UserContext)

export function UserProvider({ children }) {
  const onLogin = useSetUser()
  const tokenResult = useUserQuery()
  const context = tokenResult.data
  return (
    <FullPageLoading qResult={tokenResult}>
      {context ? (
        <UserContext.Provider value={context}>{children}</UserContext.Provider>
      ) : (
        <Public onLogin={onLogin} />
      )}
    </FullPageLoading>
  )
}

const signOut = (manual: boolean) => {
  localStorage.removeItem(TOKEN_KEY)
  rollbar.configure({ payload: { person: null } })
  // signOut functions by reloading the page
  if (manual) {
    // if signout is manual, send home
    window.location.href = '/'
  } else {
    // otherwise (e.g. expired token) reload the page
    window.location.reload()
  }
}

const useUserQuery = () => {
  const url = getInternalUrl(config.api.prefix, urlPath)
  const headers = { authorization: `Bearer ${TOKEN_STORED_AT_INIT}` }

  return useQuery({
    queryKey: USER_QUERY_KEY,
    enabled: !!TOKEN_STORED_AT_INIT, // don't run if no token
    staleTime: Infinity, // run once
    queryFn: async () => {
      // Check token if stored at init
      // If there's no token user gets set via the login process
      if (TOKEN_STORED_AT_INIT) {
        //console.log('**', url)
        try {
          const result = await fetcher.post(url, { headers }).json<LoginData>()
          return loadUserAndCreateContext(result)
        } catch (err) {
          // if the token fail, remove it
          localStorage.removeItem(TOKEN_KEY)
          // 401 is expected when the token is bad
          if (err.response?.status === 401) {
            return null
          }
          // Throw unexpected errors
          throw err
        }
      } else {
        // if no token, continue to login screen
        localStorage.removeItem(TOKEN_KEY)
        return null
      }
    },
  })
}

const loadUserAndCreateContext = (loginData: LoginData): IUserContext => {
  const { user, year, years } = loginData
  const { email, token } = user
  localStorage.setItem(TOKEN_KEY, token)
  rollbar.configure({ payload: { person: { id: email, email } } })
  Telemetry.setUser({ email })

  const fetchAs = createFetch({
    origin: config.api.prefix,
    bearer: user.token,
    on401: () => signOut(false),
  })

  const isInternal = user.domain === 'ponder.co' && user.roles.includes('admin')
  const isSuperDuperUser = user.email === 'tony@ponder.co' // workaround so i can re-run batches
  return { fetchAs, isInternal, isSuperDuperUser, signOut, user, year, years }
}

const useSetUser = () => {
  const queryClient = useQueryClient()
  return (result: LoginData) => {
    // todo: Not sure if I'm misusing react-query (also, this was written prior to v3)
    queryClient.setQueryData(USER_QUERY_KEY, loadUserAndCreateContext(result))
  }
}
