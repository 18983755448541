import type { Curriculum, FileLocation, PacketType, PdfStyle } from '..'

export type PorQ = Passage | Question
export type PageItemType = 'passage' | 'question'
export type QType = 'MC' | 'OER' | 'GRID'
export type QOption = { label: string }
export type QResp = { filledStr: string; pts: number }

export type Page = {
  items: PageItem[]
}

export type PageItem = {
  type: PageItemType
  id: string
}

export type Passage = {
  author?: string
  genre?: string
  id: string
  lexile?: string
  location?: string
  name: string
  source?: string
}

export type Question = {
  id: string
  label: string
  labelMaj: string
  labelMin?: string
  maxPts: number
  options: QOption[]
  outOf: number
  passageIds: string[]
  responses: QResp[]
  stds: string[]
  type: QType
}

export type LastUpdate = {
  time: number
  user: string
}

export type PublishStage = 'creating' | 'uploaded' | 'published' | 'recalled'

export type PdfKeySet = 'name' | 'pages' | 'parts' | 'style' | 'type'
export type AnswerKeySet =
  | 'pages'
  | 'passages'
  | 'questions'
  | '_import'
  | '_importName'

export type PdfContentSet = Pick<Packetmeta['content'], PdfKeySet>
export type AnswerKeyContentSet = Required<
  Pick<Packetmeta['content'], AnswerKeySet>
>

/** Db version of packetmeta */
export type Packetmeta = {
  _linkExternal?: string
  _printed: { first: number }
  _pub: PublishStage
  _updates: {
    answerKey: LastUpdate
    crossPacket: LastUpdate
    last: LastUpdate
    pdf: LastUpdate
    publish: LastUpdate
    stds: LastUpdate
  }
  _uploaded: boolean
  contentId: string
  content: PacketContent
  curriculumId: string
  id: string
  number: string
  src: FileLocation
}

/** Packetmeta with its curriculum */
export type PacketmetaC2 = Packetmeta & { curriculum: Curriculum }

export type AssessmentSrc = { id: string; inst: string }
export type PacketImportMeta = {
  srcs: AssessmentSrc[]
  time: number
}

export type PacketContent = {
  _import?: PacketImportMeta
  _importName?: string
  images: FileLocation[]
  name: string
  pages: Page[]
  parts: number[]
  passages: Passage[]
  questions: Question[]
  stds?: string[]
  style: PdfStyle
  type: PacketType
}

export type SchoolPacketGroup = {
  id: string
  number: string
  packets: { curriculumId: string; id: string; name: string }[]
}

/** Passage/Question API version of packetmeta */
export type PQPacketmeta = {
  /** @deprecated */
  _illuminate: string
  updates: { last: LastUpdate }
  id: string
  content: {
    passages: Passage[]
    questions: { id: string; label: string; passageIds: string[] }[]
  }
}
