import * as Yup from 'yup'
import {
  AnswerKeyContentSet,
  PacketImportMeta,
  Page,
  PageItem,
  PageItemType,
  Passage,
  QResp,
  QType,
  Question,
} from '..'

const yupPageItem = Yup.object<PageItem>({
  id: Yup.string().required(),
  type: Yup.string().oneOf<PageItemType>(['passage', 'question']).required(),
})

export const yupPage = Yup.object<Page>({ items: Yup.array().of(yupPageItem) })

const yupQOption = Yup.object({ label: Yup.string().required() })
const yupQResp = Yup.object<QResp>({
  filledStr: Yup.string().required(),
  pts: Yup.number().required(),
})

export const yupQuestion = Yup.object<Question>({
  id: Yup.string().required(),
  label: Yup.string().required(),
  labelMaj: Yup.string().required(),
  labelMin: Yup.string().nullable(),
  maxPts: Yup.number().required(),
  options: Yup.array(yupQOption).ensure(),
  outOf: Yup.number().required(),
  passageIds: Yup.array(Yup.string().required()).ensure(),
  responses: Yup.array(yupQResp).required(),
  stds: Yup.array(Yup.string().required()).ensure(),
  type: Yup.string().oneOf<QType>(['MC', 'OER', 'GRID']).required(),
})

export const yupPassage = Yup.object<Passage>({
  genre: Yup.string().nullable(),
  lexile: Yup.string()
    .max(20)
    .nullable()
    .transform((v) => v || null), // empty string back to null
  id: Yup.string().required(),
  name: Yup.string().required('Name is required'),
})

const yup_import = Yup.object<PacketImportMeta>().shape({
  srcs: Yup.array(
    Yup.object({
      id: Yup.string().required(),
      inst: Yup.string().required(),
    })
  ).required(),
  time: Yup.number().required(),
})

/*
 * TODO:
 * Not sure if the test methods are better off separate or combined
 * A bunch of it is not implemented yet
 * [x] All passages attached to a page
 * [x] All questions attached to a page
 * All questions have unique labels
 * Would be nice to reuse this on the client, but the wizard validation needs more help than I have time for now
 */
export const yupAnswerKeyContentSet = Yup.object<AnswerKeyContentSet>({
  _import: yup_import,
  _importName: Yup.string().required().trim(),
  pages: Yup.array(yupPage).ensure(),
  passages: Yup.array(yupPassage).ensure(),
  questions: Yup.array(yupQuestion).ensure(),
})
  .test(
    'page-ids-attached-and-defined',
    null,
    /**
     * Checks for:
     *   * P/Q ids in `pages` array that are not defined in { passages, questions }
     *   * P/Q ids defined in { passages, questions } that are no attached to any pages
     *
     * Users enter via our UI so any issues here would be coding errors.
     */
    function testIdsAttachedAndDefined(value: AnswerKeyContentSet) {
      const { pages, passages, questions } = value

      // todo: maybe change values to Map<id, item> for better error messaging
      const pSet = new Set(passages.map((item) => item.id))
      const qSet = new Set(questions.map((item) => item.id))

      const sets = {
        passage: {
          notDefined: new Set<string>(), // add here if not defined in `values`
          unattached: new Set<string>(pSet), // remove from here if attached to pages
          values: pSet,
        },
        question: {
          notDefined: new Set<string>(),
          unattached: new Set<string>(qSet),
          values: qSet,
        },
      }

      // Check ids...
      for (let item of pages.flatMap(({ items }) => items)) {
        const { notDefined, unattached, values } = sets[item.type]
        if (values.has(item.id)) {
          unattached.delete(item.id)
        } else {
          notDefined.add(item.id)
        }
      }

      let errors: string[] = []
      // check for errors (if sets not empty)
      for (let [itemType, { unattached, notDefined }] of Object.entries(sets)) {
        const pagesPath = `${this.path}.pages`
        const itemsPath = `${this.path}.${itemType}s`
        if (unattached.size > 0) {
          errors.push(
            [
              `${pagesPath} includes ${itemType} ids that are not defined in ${itemsPath}:`,
              JSON.stringify(Array.from(unattached)),
            ].join(' ')
          )
        }
        if (notDefined.size > 0) {
          errors.push(
            [
              `${itemsPath} includes items that are not attached to ${pagesPath}`,
              JSON.stringify(Array.from(notDefined)),
            ].join(' ')
          )
        }
      }
      if (errors.length > 0) {
        throw new Yup.ValidationError(errors, value, this.path)
      } else {
        return true
      }
    }
  )
  .test(
    'passage-unattached',
    null,
    function testPsAttached(value: AnswerKeyContentSet) {
      const { passages, questions } = value
      const attachedSet = new Set(questions.flatMap((q) => q.passageIds))
      const unattached = passages
        .map(
          (p, idx) =>
            !attachedSet.has(p.id) &&
            `${this.path}.passages[${idx}] { id: ${p.id}, name: ${p.name} } is not attached to any questions`
        )
        .filter((p) => p)
      if (unattached.length === 0) {
        return true
      } else {
        return new Yup.ValidationError(unattached, value, this.path)
      }
    }
  )
